import React from "react"

import Layout from "layout/Layout"
import Container from "layout/Container"
import SignIn from "components/Auth/SignIn"

export default ({ location }) => {
  return (
    <Layout
      title="Sign In as Doctor"
      subtitle="Please sign in to continue."
      seoTitle="Sign In as Doctor"
    >
      <Container isCentered fullhd={4}>
        <SignIn location={location} />
      </Container>
    </Layout>
  )
}
